
import { documentShareStart } from '@/api/meet'
import { officeUrl } from '@/config/index'

export async function queryFile(_data) {
  try {
    let _objectUrl = _data.uploadUrl;
    if (_objectUrl.startsWith('http://')) {
        _objectUrl = _objectUrl.replace('http://', 'https://');
    }
    let params = {
        type:"all",
        roomId:_data.meetInfo.roomId,
        url:`${officeUrl}/?docId=${_data.id}&objectUrl=${encodeURIComponent(_objectUrl)}&page=1`,
        userId:_data.meetInfo.userId,
        businessCode:_data.businessCode,
        totalPage:_data.totalPage,
        fileId: _data.id,
        metadata:{
            displayName:`${_data.meetInfo.userName}`
        },
    }
    console.error('shareStart satrt',params)
    //获取docId
    const shareData = await documentShareStart(params)

    let fileData = {
      url:encodeURI(params.url),
      docId: shareData,
      curPage:1,
      fileId:_data.id,
      uploadUrl:_data.uploadUrl
    }

    return fileData

} catch (error) {
  return error;
}
}
