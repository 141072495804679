import {getUrlDate} from "@/utils/tool"

const envConfig = {
  dev: {
    // dev分支的环境配置
    imEnv: "dev",
    meetAgendasUrl: "https://meeting-dev.100url.cn",
    signalUrl: "live.100tt.com.cn/signal-server",
    reportLogUrl:
      "https://meeting-dev-api.100url.cn/ybmeet-log/logan/web/upload.json",
    adminUrl: "https://admin.test.100meet.cn",
    meetServerUrl: "https://meeting-dev-api.100url.cn",
    middleServerUrl: "https://mg.100api.cn",
    // workBenchServerUrl: "https://npro.dev.100url.cn",
    workBenchServerUrl: "https://omnidevf.100url.cn",
    consoleUrl: "https://switcher.100url.cn",
    officeUrl:"https://doc-remote-test.100live.cn"
	},
  test: {
    // test分支的环境配置
    meetServerUrl: "https://api.test.100meet.cn",
    middleServerUrl: "https://api.test.100meet.cn",
    imEnv: "test",
    meetAgendasUrl: "https://www.test.100meet.cn",
    signalUrl: "signal.test.100rtc.com/signal-server",
    reportLogUrl:
      "https://api.test.100meet.cn/ybmeet-log/logan/web/upload.json",
    adminUrl: "https://admin.test.100meet.cn",
    // workBenchServerUrl: "https://npro.test.100url.cn",
    workBenchServerUrl: "https://omnitest.100url.cn",
    consoleUrl: "https://switcher.test.100mix.cn",
    officeUrl:"https://doc-remote-test.100live.cn"
  },
  master: {
    // master分支的环境配置
    meetServerUrl: "https://api.100meet.cn",
    workBenchServerUrl: "https://npro.100url.cn",
    middleServerUrl: "https://api.100meet.cn",
    imEnv: "prod",
    meetAgendasUrl: "https://web.100meet.cn",
    signalUrl: "signal.100rtc.com/signal-server",
    reportLogUrl: "https://api.100meet.cn/ybmeet-log/logan/web/upload.json",
    adminUrl: "https://admin.100meet.cn",
    consoleUrl: "https://console.100mix.cn",
    officeUrl:"https://doc-remote.100live.cn"
  },
};
let paramsArr = getUrlDate(window.location.href)


export const meetServerUrl = envConfig[process.env.VUE_APP_ENV].meetServerUrl;
export const middleServerUrl =
  envConfig[process.env.VUE_APP_ENV].middleServerUrl;
export const imEnv = envConfig[process.env.VUE_APP_ENV].imEnv;
export const meetAgendasUrl = envConfig[process.env.VUE_APP_ENV].meetAgendasUrl;
export const signalUrl = envConfig[process.env.VUE_APP_ENV].signalUrl;
export const reportLogUrl = envConfig[process.env.VUE_APP_ENV].reportLogUrl;
export const adminUrl = envConfig[process.env.VUE_APP_ENV].adminUrl;
export const workBenchServerUrl = paramsArr && paramsArr.domain ? 'https://' + decodeURIComponent(paramsArr.domain) : envConfig[process.env.VUE_APP_ENV].workBenchServerUrl;
export const consoleUrl = envConfig[process.env.VUE_APP_ENV].consoleUrl
export const officeUrl = envConfig[process.env.VUE_APP_ENV].officeUrl
