<template>
  <div :class="isLandscape ? 'docshow-wrapbox landscapeDoc' : 'docshow-wrapbox'" @click.stop="">
    <div id="doc-contentBox" >
      <div class="page-wrap" v-if="totalPage">
        <div class="left-icon" @click.stop="prevPage">
          <img src="../../../assets/images/icon/leftArrow.png">
        </div>
        <div class="pages">{{currentPage}} / {{ totalPage }}</div>
        <div class="right-icon" @click.stop="nextPage">
          <img src="../../../assets/images/icon/rightArrow.png">
        </div>
      </div>
      <div class="show-control">
        <div class="switch-doc" @click.stop="showDoclist">切换文档</div>
        <div class="close-doc" @click.stop="stopDoc">关闭文档</div>
      </div>

      <div class="thumbs-list" v-show="showThumbs">
        <ul v-if="thumbs.length">
          <li v-for="(item, index) in thumbs"
          :key="index"
          @click="jumpPage(index + 1)"
          :id="'thumbs_' + index"
          >
            <div class="count">{{ +index + 1 }}</div>
            <div class="thumbs-item" :class="{'active-doc': currentPage == (+index + 1) }">
              <img :src="item.src" alt="">
            </div>
          </li>
        </ul>
      </div>
      <div class="thumbs-btn" @click="showThumbs = !showThumbs">
        缩略图
      </div>

      <div class="arrow-left" @click.stop="prevPage" v-if="isLandscape">
        <img src="../../../assets/images/icon/leftArrow.png">
      </div>
      <div class="arrow-right" @click.stop="nextPage" v-if="isLandscape">
        <img src="../../../assets/images/icon/rightArrow.png">
      </div>
      <div class="close-doc-btn" @click.stop="stopDoc" v-show="currentPage == totalPage">关闭文档共享</div>
      <!-- 模态窗 -->
      <div class="modal" @click.stop="clickAction">
      </div>


    </div>
    <!-- <div class="user_dialog" v-show="docShow">
      <doc-list v-model="docShow"></doc-list>
    </div> -->
  </div>
</template>
<script>
import YB_Doc from "@ybmeet/yb_doc_sdk";
import { throttle } from 'lodash';
import { imEnv } from '@/config/index';
import { documentShareStop, reportShareData } from '@/api/meet'

export default {

  data() {
    return {
      currentPage: 1,
      docInstance: null,
      shareDocInfo: null,
      docReady: false,
      totalPage: 0,
      docShow: false,
      thumbs: [],
      showThumbs: false,
      shareReportTimer: null,
    }
  },

  mounted() {
    this.$eventBus.$on('closeShare', async (type) => {
      if(type == 1) {
        await this.stopDocShowAction();
      }
    });
    document.oncontextmenu = () => {
      return false;
    }
    this.$nextTick(async ()=> {
      const shareInfo = sessionStorage.getItem('shareDocInfo');
      if(shareInfo) {
        this.shareDocInfo = JSON.parse(shareInfo);
      }
      this.currentPage = this.shareDocInfo.curPage
      sessionStorage.setItem('doc_env', imEnv)

      try {
        let _objectUrl = this.shareDocInfo.uploadUrl || '';
        if (_objectUrl.startsWith('http://')) {
            _objectUrl = _objectUrl.replace('http://', 'https://');
        }
        this.docInstance = new YB_Doc({
          url:this.shareDocInfo.url,
          domId: "#doc-contentBox",
          docId: this.shareDocInfo.docId,
          userId: this.$configs.userId,
          initPage: this.shareDocInfo.curPage || 1,
          objectUrl: _objectUrl
        })
        this.startShareReport();
        
        // console.error(111111111112,this.shareDocInfo)
        const clientW = document.querySelector('#app').offsetWidth;
        const clientH = document.querySelector('#app').offsetHeight;
        let height, width
        if(this.isLandscape) {
          height = clientW;
          width = height * 1.7778;
        } else {
          width = clientW;
          height = width / 1.7778;
        }
        console.log('[debug info] 当前待设置的文档相关信息', `屏幕高${clientH} ,宽：${clientW}`, `计算最终结果 高： ${height} 宽： ${width}`)
        document.querySelector('#doc-contentBox').style.width = `${width}px`;
        document.querySelector('#doc-contentBox').style.height =  `${height}px`;
        // 绑定-> 初始化成功的事件监听
        this.docInstance.on('initd', async (totalPage) => {
          this.totalPage = totalPage;
          this.docReady = true;
          try {
          const data = await this.docInstance.getThumb(this.shareDocInfo.fileId)
          console.log('[debug info] docShow() -> init thumbs', data , this.shareDocInfo.fileId)
          this.thumbs = data.map(item => {
            return {
              src: item,
              loaded: false,
              loadedErr: false
            }
          })
        } catch(e) {
          console.log('[debug info] docShow() -> init thumbs', e, this.shareFireId)
        }
          // if ()
        })

        this.docInstance.on('pagechange', (page) => {
          let shareInfo = sessionStorage.getItem('shareDocInfo');
          shareInfo = JSON.parse(shareInfo);
          shareInfo.curPage = page;
          this.currentPage = page;
          sessionStorage.setItem('shareDocInfo', JSON.stringify(shareInfo));
        })

        this.docInstance.initPage();


      } catch(e) {
        console.error('实例化文档失败', e)
      }

      // 开始共享数据上报
    });
  },
  computed: {
    isLandscape() {
      return this.$store.state.meet.isLandscape
    }
  },
  watch: {
    currentPage: function(newVal) {
      this.updateDocActiveIndex(newVal)
    }
  },

  methods: {
    formatServerPos: function(Px, Py, screen) {
      let x, y;
      if(this.isLandscape) {
        x = Py;
        y = Px;
      } else {
        x = Px;
        y = Py;
      }

      let screenWidth, screenHeight;
      if(!screen) {
        const pptWrap = document.querySelector('#doc-contentBox');
        screenWidth = pptWrap.offsetWidth;
        screenHeight = pptWrap.offsetHeight;
        // if(this.isLandscape) {
        //   screenWidth = pptWrap.offsetHeight;
        //   screenHeight = pptWrap.offsetWidth;
        // }
      }
      console.log('格式化函数 called', x, y, `ScreenW: ${screenWidth} ScreenH: ${screenHeight}`)

      if(x > screenWidth || y > screenHeight) return;
      // x / screenX = targetX / 1920
      // targetX = 1920 * bits
      let targetX = (x / +screenWidth) * 1920;
      let targetY = (y / +screenHeight) * 1080;
      return {x:  Math.floor(targetX), y: Math.floor(targetY)}
    },
    clickAction: function(e){
      if(!this.docReady) return;
      let y = e.y;
      let offsetTop = this.isLandscape ? document.querySelector('#doc-contentBox').offsetLeft : document.querySelector('#doc-contentBox').offsetTop;
      y = y - offsetTop;
      let server;

      server = this.formatServerPos(e.x, y, null);

      console.log('[debug info] ClickAction() | 当前点击处理', `点击目标点${e.x} ,y：${e.y}`, `计算最终结果 data： ${server}`)
      let data = {
        x: e.x,
        y: y,
        server: {
          x: server.x,
          y: server.y
        }
      }
      if(this.isLandscape) {
        let cacheX = data.x;
        let cacheY = data.y;
        data.x = cacheY;
        data.y = cacheX;
      }
      console.log('[debug info] ClickAction() | 当前点击处理', `点击目标点${e.x} ,y：${e.y}`, `计算最终结果 data： ${data}`)
      this.docInstance.click(data);
    },

    showDoclist: function() {
      if(this.isLandscape) {
        // this.docShow = true
        this.$store.commit("meet/updateGlobalMeetState", {
          isLandscape: false
        })
      }
      this.$eventBus.$emit('showDocLists');
    },

    stopDoc: async function() {
      this.$dialog.confirm({
        title: '提示',
        message: '确认关闭正在共享的文档？'
      }).then(async () => {
        await this.stopDocShowAction()
      }).catch(e => {
        console.log('[debug info] stopDoc() called canceled');
      })
    },

    async stopDocShowAction() {
      await documentShareStop({
          fileId: this.shareDocInfo.docId,
          userId: this.$configs.userId
        })
        this.$store.commit('meet/updateGlobalMeetState', {
          isShareDoc: false,
          shareFireId: null,
          shareDocTotalPage: 0,
          curPage: 1
        })

        let userId = `${this.$route.query.roomID}_${this.$configs.userId}`

        let user = this.$store.getters["member/getUser"](userId);
        if (user.userId) {
          this.$store.commit("member/updateUser", {
            userId,
            isShare: false,
            sharePaused: false,
          });
        }
    },

    prevPage: async function() {
      if(this.currentPage == 1) return;
      await this.docInstance.prevPage()
    },

    nextPage: async function() {
      if(this.currentPage == this.totalPage) return;
      await this.docInstance.nextPage()
    },

    jumpPage: async function(page) {
      try {
        await this.docInstance.jumppage(page);
      } catch(e) {
        // if(e?.code == 401) {
        //   Message.error('token 过期')
        // }else if(e.code == 60058){
        //   Message.error('当前云分享已停止')
        // } else {
        //   Message.error('网络异常,请检查网络设置')
        // }
        return;
      }
      this.currentPage = +page;
    },

    updateDocActiveIndex: function(page) {
      let current = page ? page : this.currentPage
      try {
      document.getElementById(`thumbs_${current - 2}`).scrollIntoView();
    } catch(e) {
      console.info('没找到可以滚动的dom')
    }
    },

    /**
     * 开始共享数据上报
     * @param {number} type - 共享类型，默认为1
     */
    startShareReport(type = 1) {
      if (this.shareReportTimer) {
        clearInterval(this.shareReportTimer);
      }
      
      const reportData = async () => {
        try {
          await reportShareData({
            conferenceId: parseInt(this.$route.query.conferenceId),
            conferenceNo: this.$configs.conferenceNo,
            realName: this.$configs.userName,
            roomId: this.$configs.roomId,
            type: type,
            userId: this.$configs.userId
          });
        } catch (error) {
          console.error(`共享数据上报失败: ${error}`);
          loganLog(`共享数据上报失败: ${error}`);
        }
      };

      // 立即执行一次
      reportData();
      // 每10秒执行一次
      this.shareReportTimer = setInterval(reportData, 10000);
    },

    /**
     * 停止共享数据上报
     */
    stopShareReport() {
      if (this.shareReportTimer) {
        clearInterval(this.shareReportTimer);
        this.shareReportTimer = null;
      }
    },
  },

  beforeDestroy() {
    this.docInstance.destory()
    
    // 停止共享数据上报
    this.stopShareReport();
  }

}
</script>
<style lang="less">
  .docshow-wrapbox{
    display: flex;
    width: 100%;
    height: 100%;
    background: #000;

    &.landscapeDoc{
        position: relative;
        width: 100vh !important;
        height: 100vw !important;
      }
      .user_dialog{
        background-color: #fff;
        height: 100vw;
        width: 40vh;
        position: absolute;
        right: 0;
        z-index: 999;
      }
    #doc-contentBox{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      aspect-ratio: 16/9;
      overflow: hidden;
      margin: auto;
      iframe{
        position: absolute !important;
        top: 0;
      }

      .modal{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .thumbs-btn{
      position: absolute;
      z-index: 33;
      background: rgba(0, 0, 0, .3);
      width: 180px;
      border-radius: 30px;
      height: 48px;
      bottom: 35px;
      right: 24px;
      line-height: 48px;
      overflow: hidden;
      text-align: center;
    }
    .thumbs-list {
      position: absolute;
      top: 20px;
      right: 10px;
      bottom: 100px;
      z-index: 33;
      background: rgba(0, 0, 0, .3);
      border-radius: 10px;
      width: 220px;
      overflow: scroll;
      li {
        display: flex;
        height: 80px;
        line-height: 90px;
        margin: 10px 10px;
        .count{
          width: 40px;
          text-align: center;
        }
        .thumbs-item{
          width: 140px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .active-doc {
            border: 2px solid #1AB370;
            border-radius: 4px;
            position: relative;
            &::after{
              content: '';
              color: #fff;
              position: absolute;
              right: -16px;
              top: -16px;
              width: 32px;
              height: 32px;
              border-radius: 50px;
              background-color: #1AB370;
              background-image: url('../../../assets/images/icon/doc-select-icon.png');
              background-repeat: no-repeat;
              background-size: 33%;
              background-position: 4px 16px;
            }
          }
      }
    }

    .page-wrap{
      position: absolute;
      z-index: 55;
      background: rgba(0, 0, 0, .3);
      width: 180px;
      border-radius: 30px;
      height: 48px;
      bottom: 35px;
      left: 24px;
      display: flex;
      overflow: hidden;
      &>div{
        &.left-icon,&.left-icon{
          width: 48px;
        }
        &.pages{
          flex: 1;
          height: 48px;
          line-height: 48px;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
      }
      img{
        width: 48px;
      }
    }
    .show-control{
      position: absolute;
      width: 288px;
      height: 48px;
      color: #fff;
      line-height: 48px;
      bottom: 35px;
      left: 50%;
      z-index: 55;
      overflow: hidden;
      display: flex;
      border-radius: 30px;
      transform: translateX(-50%);
      display: flex;
      overflow: hidden;
       &>div{
        flex: 1;
        text-align: center;
        background: rgba(0, 0, 0, .3);
        &:active{
          background: rgba(0, 0, 0, .7);
        }
       }
    }

    .arrow-left{
      position: absolute;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 100%;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 55;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      img{
        width: 90%;
      }
    }
    .arrow-right{
      position: absolute;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 100%;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 55;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      img{
        width: 90%;
      }
    }

    .close-doc-btn{

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 232px;
      height: 88px;
      line-height: 88px;
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
      border-radius: 44px;
      font-size: 28px;
      z-index: 55;
      color: #FF4D4F;
      text-align: center;
    }
  }
</style>
