<template>

  <van-popup :value="show" position="bottom" :overlay="false" :lock-scroll="false" class="meet-footer" @click.stop="">

    <chat ref="chat" @open-chat="showChat = true" @close-chat="showChat = false"></chat>
    <!-- 聊天弹窗 -->
    <chat-list @close="showChat = false" v-model="showChat"></chat-list>

    <info v-model="showInfo"></info>
    <more v-model="showMore"></more>

    <!-- 成员管理 -->
    <member v-model="showMember"></member>

    <!-- 文档列表 -->
    <doc-list v-model="showDocs"></doc-list>
    <!-- 翻页组件 -->
    <div class="pagination" v-if="orientation == 'landscape' && galleryUsers.length > 0">

      <span class="prev" @click.stop="$eventBus.$emit('gallery-prev')">
        <my-icon fontSize="48" iconName="icon-jiantou_xiajiantou_48_bai"></my-icon>
      </span>

      <span @click.stop="$eventBus.$emit('gallery-next')" class="next">
        <my-icon fontSize="48" iconName="icon-jiantou_xiajiantou_48_bai"></my-icon>
      </span>

    </div>


    <div class="handle-wrapper">
      <!-- 音视频操作 -->

      <media-handler></media-handler>
      <!-- 其他功能操作 -->
      <div class="minor">
        <my-icon v-for="item in minor_actions" :key="item.key" :iconName="item.iconName" fontSize="48"
          @click="handler(item)"></my-icon>
        <div class='circle' v-if='showNewChatTips'></div>
        <div class='circle' v-if='$parent.isShowWenjuanTips'></div>
      </div>

      <!-- 有人举手的提示 -->
      <div v-if="raisehandPopoverShow && !raisehandPopoverClicked && raisehandCount > 0" class="raisehand-popover">
        <div class="content">
          <my-icon fontSize="48" iconName="icon-jushou_48_bai"></my-icon>
          <span>{{ raisehandCount }}</span>
        </div>
        <div class="arrow"></div>
      </div>
    </div>
  </van-popup>

</template>



<script>

import Chat from './OnChat.vue';
import ChatList from './OnList.vue';
import Info from './Info.vue'
import More from './More.vue'
import Member from './Member.vue'
import MediaHandler from './MediaHandler.vue'
import docList from '@/components/documentList'

import { ROLE_CODE } from "@/constant/index";


export default {
  props: {
    showToolbar: Boolean,
    orientation: String
  },

  components: {
    Chat,
    ChatList,
    info: Info,
    more: More,
    member: Member,
    MediaHandler,
    docList
  },

  data() {
    return {
      showMember: false,
      showInfo: false,
      showMore: false,
      showChat: false,
      showDocs: false,
      raisehandPopoverClicked: false
    }
  },
  computed: {

    // 判断是否展示翻页按钮
    galleryUsers() {
      return this.$store.getters['member/pageList'];
    },
    showNewChatTips() {
      //是否展示最新消息提醒
      return this.$store.state.meet.showNewChatTips
    },

    show() {
      return this.showToolbar
    },

    localUser() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },

    lockedState() {
      // 会议锁定
      return this.$store.state.meet.lockedState;
    },

    raisehandPopoverShow() {
      //是否展示举手提示
      return this.$store.state.meet.raisehandPopoverShow;
    },

    minor_actions() {
      let _arr = [
        {
          iconName: this.lockedState ? `icon-huiyisuoding_48_hei` : `icon-member`,
          handle: () => {
            this.showMember = !this.showMember
          },
          key: 'member'
        },
        {
          iconName: `icon-info`,
          handle: () => this.showInfo = !this.showInfo,
          key: 'info'
        },
        {
          iconName: `icon-more`,
          handle: () => this.showMore = !this.showMore,
          key: 'more'
        },
      ];

      // 只在 projectType == 10 时添加共享图标
      if (this.$store.state.meet.projectType == 10) {
        _arr.unshift({
          iconName: 'icon-liebiao_gongxiang',
          handle: () => {
            if(this.$store.state.meet.sharePermission == 0 || !this.isOrdinaryUser) {
              this.showDocs = !this.showDocs
            } else  {
              Toast('当前仅主持人可发起共享')
            }
          }
        });
      }

      return _arr;
    },


    // 举手人数
    raisehandCount() {

      let count = 0;

      const { userList } = this.$store.state.member;

      for (let i = 0; i < userList.length; i++) {
        const element = userList[i];

        if (element.isRaiseHand) {
          count++;
        }
      }


      return count;
    }
  },


  mounted() {
    this.$eventBus.$on('showDocLists', () => {
      console.log('[debug handler func] 事件通知, 开启文档列表')
      this.showDocs = true
    })
  },
  methods: {

    /******** 按钮点击事件 **************/
    handler(item = {}) {
      if (item.handle) item.handle();
    },
  },

  beforeDestroy() {
    this.$eventBus.$off('showDoclists')
  },

  watch: {

    raisehandCount(newVal) { // 监听举手人数变化

      if (!this.localUser) return false;
      if (this.localUser.roleCode == ROLE_CODE.USER) return false; // 普通用户不显示

      if (newVal == 0) {
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: false
        })
        return;
      }

      this.raisehandPopoverClicked = false;
      this.$store.commit("meet/updateGlobalMeetState", {
        raisehandPopoverShow: true
      })

    },

    showChat(newVal) { // 监听聊天半屏是否展示
      this.$store.commit("meet/updateGlobalMeetState", {
        showChatPop: newVal
      })
    },

    showMember(newVal) { // 监听成员半屏是否展示
      if (newVal && this.raisehandPopoverShow) {
        this.raisehandPopoverClicked = true
      } else {
        //关闭成员半屏 举手提示消失
        this.raisehandPopoverClicked = false
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: false
        })
      }
    }

  }
}

</script>

<style lang="less" scoped>
.meet-footer {
  width: 100%;
  background: transparent;
  padding: 0 24px 52px;
  position: absolute;
  overflow: inherit;

  .pagination {
    width: 152px;
    height: 64px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 36px;

    flex-shrink: 0;
    margin-right: 64px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    .prev {
      transform: rotate(90deg);
    }

    .next {
      transform: rotate(-90deg);
    }

  }
}

.handle-wrapper {

  width: 680px;
  height: 104px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 52px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  padding-right: 40px;
  padding-left: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;


  .minor {
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .circle {
      position: absolute;
      width: 16px;
      height: 16px;
      background: #FF4D4F;
      padding: 0;
      border-radius: 100%;
      top: -2px;
      left: 97%;
    }
  }



  .raisehand-popover {
    position: absolute;
    right: 200px;
    width: 116px;
    height: 100px;

    transform: translate3d(0, -100%, 0);

    background: transparent;
    font-size: 30px;
    color: #fff;

    .content {
      border-radius: 10px;
      background: #1DAF69;

      width: 100%;
      height: 88px;
      padding: 8px;
      box-sizing: border-box;

      display: flex;
      justify-content: center;
      align-items: center;

      image {
        width: 48px;
        height: 48px;
        display: flex;
      }

      text {
        margin-left: 5px;
      }
    }

    .arrow {
      position: absolute;
      bottom: 0;
      left: 50%;

      border-top: 12px solid #1DAF69;
      border-right: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid transparent;

      transform: translate(-50%, 50%);
    }
  }


}


/* 横屏 */
.landscape .meet-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .message-input {

    margin-bottom: 0;
  }
}
</style>
