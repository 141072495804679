<template>

  <div class="gallery" @click.stop="$emit('switch-toolbar')">
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      style="width:100%;height:100%"
    >
      <swiper-slide
        key="speaker"
      >
        <div 
          class="speaker"
          v-if="userMain"
        >
          <local-video-item
            v-if="userMain.userId == $configs.peerId && !isShareDoc"
            :user="userMain"
            :key="'speakerView-'+userMain.userId"
            :notHighlight='true'
          ></local-video-item>
          <doc-show
            v-else-if="isShareDoc && !isLandscape"
          >
          </doc-show>
          
          <video-item
            v-else
            :user="userMain"
            :speakerView="true"
            :key="'speakerView-'+userMain.userId"
          ></video-item>
        </div>
        <div
          class="local"
          :class="[userList.length == 1 ? 'only-one' : '']"
          :style="[userList.length == 1 ? { top:0,left:0 } : '']"

          v-drag
        >

          <local-video-item
            :user="selfInfo"
            :speakerView="true"
            :style="{
              visibility: abbreviationUser ? 'hidden' : 'visible',
              height:  abbreviationUser ? '1px' : '100%'
            }"
          ></local-video-item>
          
          <!-- 共享状态下的发言方 -->
          <video-item
            v-if="abbreviationUser"
            :user="abbreviationUser"
            :key="'abbreviation-'+abbreviationUser.userId"
            :speakerView="true"
            :noSubscribeShare="true"
          >

          </video-item>
        </div>
      </swiper-slide>

      <swiper-slide
        v-for="(item) in galleryUsers"
        :key="item.page"
      >

        <v-touch
          class="user-wrapper"
          :class="'layout-'+item.count"
          v-for="(video_item,i) in item.list"
          :key="i"
          v-on:doubletap="() => enlargeVideo(video_item)"
        >
          <local-video-item
            v-if="video_item.userId == $configs.peerId"
            :user="video_item"
          ></local-video-item>


          <video-item
            v-else
            :user="video_item"
            :page="item.page"
          ></video-item>
        </v-touch>


      </swiper-slide>

      <div
        class="swiper-pagination"
        slot="pagination"
        v-if="galleryUsers.length > 0"
      >
        {{pageIndex + 1}}/{{total}}
      </div>
    </swiper>


  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'

import VideoItem from './VideoItem.vue';
import LocalVideoItem from './LocalVideoItem.vue';
import MyIcon from '@/components/MyIcon.vue';
import DocShow from './docShow.vue';

import { loganLog } from "@/utils/log"


export default {
  components: {
    'video-item': VideoItem,
    'local-video-item': LocalVideoItem,
    'doc-show': DocShow,
    Swiper,
    SwiperSlide,
    MyIcon,
  },

  mounted () {
    this.swiper = this.$refs.mySwiper.$swiper;
    this.swiper.on('slideNextTransitionEnd', () => this.slideChangeTransitionEnd('next') );

    this.swiper.on('slidePrevTransitionEnd', () => this.slideChangeTransitionEnd('prev') );

  },


  data() {
    return {

      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },

      },

      monitored: false,  // 是否已经监听 video pause

    }
  },

  computed: {
    isLandscape() {
      const { isLandscape } = this.$store.state.meet;
      return isLandscape;
    },

    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    userList() {
      return this.$store.state.member.userList
    },

    selfInfo() {

      let localUser = this.$store.getters["member/getUser"](this.$configs.peerId);

      return localUser;
    },

    userMain() { // 发言的用户

      let userMain = this.$store.getters["member/userMain"];
      return userMain;
    },

    // 右上角 显示发言方
    abbreviationUser() {
      
      let abbreviationUser = this.$store.getters["member/abbreviationUser"];
      return abbreviationUser;

    },

    isFocusScreen() {
      return this.$store.state.meet.isFocusScreen;
    },

    galleryUsers() {

      if(this.swiper) {
        if(this.pageIndex < 1) {
          this.swiper.activeIndex = 0
        }else if(this.pageIndex == 1) {
          this.swiper.activeIndex = 1

        } else {
          this.swiper.activeIndex = 2
        }

      }
      return this.$store.getters['member/pageList'];

    },


    pageIndex() {
      return this.$store.state.member.pageIndex
    },
    
    total() {
      if(this.userList.find(item => item.isShare) && this.isFocusScreen != 0){
        return this.$store.getters['member/maxPageIndex'] + 1 +1 //如果是有焦点画面并且有共享
      } else {
        return this.$store.getters['member/maxPageIndex'] + 1
      }
    }
  },

  methods: {

    slideChangeTransitionEnd(type) {
      // 防止快速 滑动
      this.swiper.disable();
      setTimeout(() => {
        this.swiper.enable();
        console.log('this.swiper.enable');
      },100)

      if(type == 'next') {
        this.$store.commit('member/updatePageIndex', this.pageIndex + 1);
      } else {
        this.$store.commit('member/updatePageIndex', this.pageIndex - 1);
      }

    },
      
    
    // 放大视频
    enlargeVideo(item) {
      // 没有视频和共享 不允许点击
      if(item.isUseShiPin || item.isShare && item.isTopShare){
        this.$eventBus.$emit('enlarge-video', item)
      }
    },

  },

  watch: {

    'userMain.isShare': function(newVal){
      if(newVal) {
        this.$store.commit('member/updatePageIndex', 0);
        
        setTimeout(() => {
          this.swiper.slideTo(0)
        },30);

        this.$eventBus.$emit("resume-video-size"); // 收起全屏观看
      }
    }

  },

  // 拖拽指令
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let block = el;   //获取当前元素
        var oW, oH;
        block.addEventListener("touchstart", function(e) {
            e.stopPropagation();
            // e.preventDefault();

            const onlyOne = block.classList.contains('only-one');
            if(onlyOne) return; //只有自己不滑动

            var touches = e.touches[0];
            oW = touches.clientX - block.offsetLeft;
            oH = touches.clientY - block.offsetTop;
            document.addEventListener("touchmove", {},false);


        }, false)
    
        block.addEventListener("touchmove", function(e) {
            e.stopPropagation();
            e.preventDefault();
            var touches = e.touches[0];
            var oLeft = touches.clientX - oW;
            var oTop = touches.clientY - oH;
            if (oLeft < 0) {
                oLeft = 0;
            } else if (oLeft > document.documentElement.clientWidth - block.offsetWidth) {
                oLeft = (document.documentElement.clientWidth - block.offsetWidth);
            }
            if (oTop < 0) {
                oTop = 0;
            } else if (oTop > document.documentElement.clientHeight - block.offsetHeight) {
                oTop = (document.documentElement.clientHeight - block.offsetHeight);
            }
            block.style.left = oLeft + "px";
            block.style.top = oTop + "px";
        }, false);
    
        block.addEventListener("touchend", function() {
            document.removeEventListener("touchmove", {},false);
        }, false);

      }
    }
  },





}
</script>

<style  scoped>
  .gallery {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;


    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .speaker {
    width: 100%;
    height: 100%;
  }

  .local {
    width: 180px;
    height: 320px;

    position: absolute;
    top: 128px;
    right: 24px;
    z-index: 9;
    transform: translateZ(0); 

  }

  .local.only-one {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;
  }

  /* swiper 相关 */
  .swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }

  .swiper-pagination {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate3d(-50%,0,0);

    padding: 5px 30px;
    border-radius: 30px;
    background: rgba(0, 0, 0, .45);

  }
  .user-wrapper {
    width: 100%;
    height: 100%;

    border: 1px solid #000;
  }
  .layout-1 {
    width: 100%;
    height: 100%;
  }
  .layout-2 {
    width: 100%;
    height: 50%;
  }
  .landscape .layout-2 {
    width: 50%;
    height: 100%;
  }
  .layout-3 {
    width: 50%;
    height: 50%;
  }
  .layout-4 {
    width: 50%;
    height: 50%;
  }


  /* 翻页按钮 */
  .page-btn {
    width: 40px;
    height: 100px;
    background: rgba(151, 135, 135, .45);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transform: translate3d(0, 0, 0);
  }

  .page-btn.prev {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 3;

  }
  .page-btn.next {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 2;

  }


</style>
