<template>

  <van-popup 
    :value="value" 
    @input="val => this.$emit('input', val)" 
    position="bottom" 
    round 
    class="meet-info"
    :get-container="getContainer"
  >
    <div class="title">{{$t('meeting.infoTitle')}}</div>
    <div class="cell name">{{meetName}}</div>
    <!-- 会议号 -->
    <!-- <div class="cell">
      <span class="color-666">{{$t('meeting.meetID')}}</span>
      <div>
        <span>{{$tools.formatCode(conferenceNo)}}</span>
        <span class="copy" v-clipboard:copy="conferenceNo" v-clipboard:success="() => onCopy('conferenceNo')"
          v-clipboard:error="onError">{{$t('meeting.copy')}}</span>
      </div>
    </div> -->
    <!-- 主持人 -->
    <!-- <div class="cell">
      <span class="color-666">{{$t('meeting.host')}}</span>
      <span :title="$store.state.meet.ownerName">{{ strMiddleEllipsis($store.state.meet.ownerName, 15, 5, 3) }}</span>
    </div>
    <div class="cell" v-if='$store.state.meet.passwordEnable && $store.state.meet.password'>
      <span class="color-666">{{ $t('login.meetPsd') }}</span>
      <span >{{ $store.state.meet.password }}</span>
    </div> -->

    <!-- 参会者ID -->
    <div class="cell">
      <span class="color-666">参会者ID</span>
      <span class="link-right">
        <span class="links">{{userID}}</span>
        <span class="copy" style="flex-shrink: 0" v-clipboard:copy="userID" v-clipboard:success="() => onCopy('userID')"
          v-clipboard:error="onError">{{$t('meeting.copy')}}</span>
      </span>
    </div>
  </van-popup>
</template>
<script>
  import {
    strMiddleEllipsis
  } from '@/utils/str'

  export default {
    data() {
      // console.log(this.$route.params.userID,'哈哈哈哈哈说this.$route.params.userID')
      const userID = this.$route.params.userID
      const conferenceNo = this.$route.query.conferenceNo
      return {
        userID,
        conferenceNo,
        timer: null,
        panel: false,
      };
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      meetName() {
        return this.$store.state.meet.meetName
      },
    },
    created(){
       this.links = window.location.host+'/join?conferenceNo=' + this.conferenceNo
    },
    methods: {
      strMiddleEllipsis,
      onCopy(type) {

        const toastMap = {
          conferenceNo: this.$t('meeting.meetID'),
          links: this.$t('seeting.inviteInfo'),
          userID: '参会者ID'
        }

        if(toastMap[type]) this.$toast(`${toastMap[type]}` + this.$t('seeting.succCopy'))
        
      },
      // 复制失败时的回调函数
      onError(e) {},

      getContainer() {
        return document.querySelector('.meeting-page')
      }
    }
  }

</script>

<style lang="less" scoped>
  // 会议信息
  .meet-info {
    height: 314px;
    color: #000;
    background: #fafafa;

    .title {
      width: 100%;
      line-height: 88px;
      text-align: center;

      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }

    .cell {
      line-height: 104px;
      border-bottom: .5px solid #eee;

      padding: 0 40px;

      display: flex;
      justify-content: space-between;

      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      background: #fff;
    }

    .name {
      margin-bottom: 16px;
      word-break: break-all;

    }

    .links {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }

    .link-right {
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 150px);
    }

    .copy {
      color: #1AB370;
      margin-left: 24px;
    }

    .color-666 {
      color: #666;
    }

  }

</style>
