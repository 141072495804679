<template>
  <div style="height: 100%;">
    <van-popup
      :value="value"
      @input="val => this.$emit('input', val)"
      round
      :closeable="!isAnymous"
      close-icon-position="top-left"
      close-icon="arrow-left"
      position="bottom"
      :lock-scroll="false"
      @click.stop=""
      :get-container="getContainer"
      :class="'portrait-doc'"
    >
    <van-tabs
      type="card"
      @click="tabChangeAction"
      v-model="tabtype"
      class="doc-tabs"
      v-if="!isAnymous"
    >
      <van-tab name="1" title="我的文档">
      </van-tab>
      <van-tab name="2" title="会议文档">
      </van-tab>
    </van-tabs>
    <div class="doc-tabs" v-else>
      <span class="anymousTitle">
        会议文档
      </span>
    </div>
      <div class="doc-lists">
        <ul v-if="fileList.length > 0">
          <li v-for="(item) in fileList" :key="item.id">
            <div class="icon">
              <img v-if="item.docType == 0" width="44" src="@/assets/images/pdf.png" alt="">
              <img v-if="item.docType == 1" width="44" src="@/assets/images/word.png" alt="">
              <img v-if="item.docType == 2" width="44" src="@/assets/images/png.png" alt="">
              <img v-if="item.docType == 3" width="44" src="@/assets/images/im.png" alt="">
            </div>
            <div class="file-name">
              <p>{{ item.name }}</p>
              <p class="file-time">{{ formTime(item.createTime) }} &nbsp;&nbsp; {{ formSize(item.size) }}</p>
            </div>

            <div class="more-icon">
              <my-icon
                v-if="!isAnymous"
                @click="editorDocItem(item)"
                iconName="icon-more"
                fontSize="48"
              ></my-icon>
              <span v-else @click.stop="startDocshow(item)">
                演示
              </span>
            </div>
          </li>
        </ul>
        <div class="no-content" v-else>
          暂无文档
        </div>
      </div>
    </van-popup>

    <pop-up-bottom
      v-model="showControl"
    >
      <div class="menu-content">
        <!-- <div class="text title">{{ member.userName }}</div> -->
        <!-- 解除静音 -->
        <div class="text" @click="startDocshow(editorItem)">
          演示文档
        </div>
        <div class="text red" @click="delDoc(editorItem)" v-if="editorItem && editorItem.userId == $configs.userId">
          删除文档
        </div>
      </div>
    </pop-up-bottom>
  </div>
</template>
<script>
import { ROLE_CODE, CONTROL_COMMAND } from "@/constant/index";
import { fetchMeetControl,quertDocumentList,deleteDocumen } from '@/api/meet'
import PopUpBottom from '@/components/PopUpBottom/index'
import debounce from 'lodash.debounce'
import { loganLog } from "@/utils/log"
import {getDocDate, formatdate} from '@/utils/date'
import { stringify } from 'qs';
import { getLocalUserInfo } from "@/utils/auth"
import { queryFile } from '@/lib/core/documentManager'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'pop-up-bottom': PopUpBottom
  },

  data() {
    return {
      tabtype: '1', // 1. 我的文档  2.会议文档
      fileList: [],
      showControl: false,
      isAnymous: false,
      editorItem: null,
    }
  },

  watch: {
    value(newVal){
      if(newVal) {
        this.getDocList()
      }
    }
  },

  mounted() {
    // const userInfo = getLocalUserInfo();
    // console.error
    // if(userInfo && Object.keys(userInfo).length > 0) {
    //   this.isAnymous = false
    // } else {
    //   this.tabtype = 2;
    // }
    this.getDocList()
  },

  computed: {
    userList() {
      // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },
    ownerId() { // 会议所有者id
      return this.$store.state.meet.ownerId
    },
    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    shareFireId() {
      return this.$store.state.meet.shareFireId
    },

    sharePermission() {
      return this.$store.state.meet.sharePermission;
    },

    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId)
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },
    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },
  },

  methods: {
    tabChangeAction(e) {
      console.log('[debug info] tab 被点击了。', e);

      this.$nextTick(() => {
        this.getDocList()
      })
    },
    editorDocItem(item) {
      this.editorItem = item;
      this.showControl = true;
    },

    // 列表时间处理
    formTime(time){
        let date = new Date(time)
        return formatdate(date, 'yyyy-M-d h:m')
    },

    // 列表文件大小
    formSize(filesize){
      if (filesize < 1024) {
        return filesize + 'B'
      } else if (filesize < (1024 * 1024)) {
        let temp = filesize / 1024
        temp = temp.toFixed(2);
        return temp + 'KB'
      } else if (filesize < (1024 * 1024 * 1024)) {
        let temp = filesize / (1024 * 1024)
        temp = temp.toFixed(2);
        return temp + 'M'
      }
    },

    // 查询文件列表
    async getDocList() {
      console.log('当前config info', this.$configs, this.tabtype)
      const routeQuery = this.$route.query
      let isUseMyGeRen = this.$configs.conferenceNo.length == 10 //是否个人会议号
      // console.error(222222,isUseMyGeRen,this.$configs.conferenceNo.length)
      let param={
        type: this.tabtype == 1 ? 0 : (isUseMyGeRen ? 2 : 1),
        conferenceId: routeQuery.conferenceId,
        userId: this.tabtype == 1 ? this.$configs.userId : this.ownerId,
        conferenceNo: this.$configs.conferenceNo,
        saveForever: true,
      }

      const resData = await quertDocumentList(param)
      if(!this.fileList.length){
          this.fileList = resData.records
      }else{
          let uploadingDocArr = this.fileList.filter(item=> item.status === 'uploading');
          this.fileList = [...uploadingDocArr,...resData.records]
      }

      console.log('[debug info] current docs', this.fileList)
    },

    async startDocshow(item) {
      if(this.sharePermission && (!this.isHost && !this.isCoHost)) {
        this.$toast('请向主持人申请共享权限')
        return;
      }else if(this.isExitUserShare && !this.isShareDoc && (!this.isHost && !this.isCoHost)) {
        this.$toast('当前有人正在共享')
        return;
      } else if(this.isExitUserShare && !this.isShareDoc && (this.isHost || this.isCoHost)) {
        this.$dialog.confirm({
          title: '提示',
          message: '当前有人正在共享，是否先停止当前正在进行的共享？',
          cancelButtonText: "不停止",
          confirmButtonText: '停止'
        }).then(async () => {
          try {
            await fetchMeetControl({
              command: CONTROL_COMMAND.STOP_SHARE,
              toPeerId: this.isExitUserShare.userId
            }, this.$configs)
          } catch (error) {
            console.error(`停止指定用户屏幕共享失败--->${error}`)
            loganLog(error, '会控异常|停止共享')
          }
        }).catch(e => {
          console.error('取消停止当前共享')
        })
        return;
      };

      if(this.isShareDoc) {
        if(this.sharePermission) {
          if(this.isOrdinaryUser) {
            this.$toast('当前仅主持人可共享')
            return;
          }
        }

        // this.$dialog.confirm({
        //   title: '提示',
        //   message: '是否停止当前正在进行的共享？',
        //   cancelButtonText: "不停止",
        //   confirmButtonText: '停止'
        // }).then(() => {
          // type: 1, 直接停止 ， 2，给出确认
          this.$eventBus.$emit('closeShare', 1);
          this.$emit('input', true)

          await new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve()
            }, 1500)
          })
        // }).catch(e => {
        //   console.error('取消停止当前共享')
        // })

        // return;
      };

      let _data= {
          id: item.id,
          isShareDoc: this.isShareDoc,
          meetInfo: {userName: this.$configs.userName,roomId: this.$configs.roomId,userId: this.$configs.userId},
          officeUrl:item.officeUrl,
          businessCode:item.businessCode,
          totalPage:item.totalPage,
          uploadUrl:item.uploadUrl
        }

        const shareDocInfo = await queryFile(_data);
        console.log('当前共享信息', shareDocInfo)

        shareDocInfo.userId = this.$configs.userId

        sessionStorage.setItem('shareDocInfo', JSON.stringify(shareDocInfo))
        this.$store.commit('meet/updateGlobalMeetState', {
          isShareDoc: true,
          shareFireId: item.id,
          shareDocTotalPage: item.totalPage,
          curPage: 1
        });
        this.$emit('input', false)
        // this.value = false
    },

    delDoc(item) {
      console.log('[debug info] del 删除文档', this.shareFireId ,item)
      console.log('[debug info] del 删除文档 shareFileId', this.shareFireId)
      if(this.shareFireId == item.id) {
        this.$toast('文档应用中，无法删除！')
        return;
      }
      this.$dialog.confirm({
        title: '提示',
        message: '是否删除已上传的文档?'
      }).then(() => {
        this.delDocAction(item)
      })
    },

    async delDocAction(item) {
      try {
        await deleteDocumen([item.id])
        this.$toast('删除成功');
        console.log('fileList',this.fileList)
        this.fileList = this.fileList.filter((doc) => {
          return doc.id != item.id
        })
      } catch(e) {
        this.$toast(e.msg || '删除失败');
      }

    },

    getContainer() {
      return document.querySelector('.meeting-page')
    }
  },

}
</script>

<style lang="less">
  .portrait-doc{
    height: 80%;
  }
  .doc-lists{

    margin-top: 50px;
  }
  .doc-tabs{
    margin:38px auto 0;
    width: 480px;
    overflow: hidden;
    >span.anymousTitle{
      display: block;
      text-align: center;
      color: #333;
      text-align: center;
      font-size: 32px;
    }
    .van-tabs__nav--card {
      border-color: #1AB370;
    }
    .van-tab.van-tab--active{
      background-color:#1AB370;
    }
    .van-tabs__nav--card .van-tab{
      color: #1AB370;
    }
    .van-tabs__nav--card .van-tab.van-tab--active{
      color: #fff;
    }
    .van-tabs__nav--card .van-tab{
      border-color: #1AB370;
    }
  }
  .doc-lists .no-content{
    margin: 45% auto;
    font-size: 36px;
    text-align: center;
    color: #333;
  }
  .doc-lists ul li{
    position: relative;
    height: 120px;
    box-sizing: border-box;
    padding: 16px 32px;
    margin-bottom: 8px;
    white-space: nowrap;
    .icon{
      display: inline-block;
      width: 88px;
    }
    .file-name{
      display: inline-block;
      margin-right: 100px;
      margin-left: 8px;
      width: calc(100vw - 252px);
      p{
        margin: 0;
        font-size: 32px;
        color: #000;
        line-height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
      }
      p.file-time{
        margin-top: 6px;
        line-height: 32px;
        font-size: 24px;
        color: #999;
      }
    }
    .more-icon{
      position: absolute;
      right: 32px;
      top: 36px;
      span {
        color: #1AB370;
        font-size: 28px;
      }
    }
  }
</style>

<style lang="less" scoped>
.menu-content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  .text {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }
  .text.title {
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 400;
    color: #999999;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;

  }
  .text.red {
    color:#F35B67
  }
  .text .icon {
    position: absolute;
    top: 24px;
    right: 159px;
  }
}
</style>
