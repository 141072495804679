


<template>

  <div 
    :class="contionalClass"
  >

      <span 
        class="collapse-fullscreen"
        @click.stop="resumeVideoSize"
      >

        <my-icon 
          iconName="icon-collapse-fullscreen" 
          fontSize="40"

        ></my-icon>

      </span>

      <div v-if="enlargeVideoShow" style="width:100%;height:100%">
          <local-video-item
            v-if="user.userId == $configs.peerId"
            :user="user"
            :key="'enlarge-'+user.userId"
            :notHighlight='true'
          ></local-video-item>

          <video-item
            v-else
            :user="user"
            :speakerView="true"
            :key="'enlarge-'+user.userId"
            :noSubscribeShare="isFocusScreen==0"
          ></video-item>
      </div>

  </div>

</template>


<script>

import VideoItem from './VideoItem.vue';
import LocalVideoItem from './LocalVideoItem.vue';


export default {
    components: {
      VideoItem,
      LocalVideoItem,
    },
    data() {
      return {
        enlargeVideoShow: false,
        user: null,
        mirror: false
      }
    },

    computed: {
      contionalClass() {
        let _o = {
          "enlarge-video" : true
        };

        if(!this.enlargeVideoShow) _o.hide = true;

        // 镜像
        
        return _o 
      },
        
      isFocusScreen() {
        return this.$store.state.meet.isFocusScreen;
      },
    },

    mounted() {
      // 监听视频放大
      this.$eventBus.$on("enlarge-video", this.enlargeVideo);
      this.$eventBus.$on("resume-video-size", this.resumeVideoSize);
      this.$eventBus.$on("user-leave", userId => {
        if(this.user && userId == this.user.userId) {
          this.resumeVideoSize()
        }
      })
    },


    methods: {


      enlargeVideo(item) {
        const userId = item.userId
        if(this.enlargeVideoShow) return;

        this.enlargeVideoShow = true;

        // const id = e.target.getAttribute('id') || e.target.getAttribute('class'), userId = id.split('-')[1];

        if(userId == this.$configs.peerId) this.mirror = true;
        this.user = this.$store.getters["member/getUser"](userId);
        this.user.isCopyShare = item.isTopShare
      },

      resumeVideoSize() {
        if(!this.enlargeVideoShow) return;

        this.enlargeVideoShow = false;
        this.mirror = false;

        this.user = null;
      },
      checkResumeVideoSize(item,type){
        if(!this.enlargeVideoShow) return;
        if(type == 'share'){
          if(this.user.isCopyShare) this.resumeVideoSize()
        }else{
          if(item.userId == this.user.userId) this.resumeVideoSize()
        }
      }
    },



}
</script>


<style lang="less" scoped>

  .enlarge-video {
    background: #222;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;

    transition: .5s;

    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
    }

    video.mirror {
       transform: rotateY(180deg);
      -webkit-transform:rotateY(180deg); /* Safari and Chrome */
      -moz-transform:rotateY(180deg); /* Firefox */
    }

    .collapse-fullscreen {

      position: absolute;
      bottom: 60px;
      right: 48px;
      z-index: 9;
      
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      transform: translateZ(0);

    }
  }

  .enlarge-video.hide {
    width:0;
    height: 0;

    transition: 0.5s;
  }

  .enlarge-video.landscape {
    width: 100vh;
    height: 100vw;

    transform: rotate(90deg) translateX(-100vw);
    transform-origin: 0% 100%;

    transition: 0.5s;
  }


</style>



